<template>
  <div class="meeting-bullet">
    <div class="meeting">
      <div class="column">
        <div>
          <strong>Date et heure</strong> {{ formatedDate }}
        </div>
        <div>
          <strong>Durée</strong> 30 min
        </div>
        <div class="coach">
<!--          <img :src="coach.image" />-->
          <div>
            <strong>Coach</strong> {{ meeting.coach.first_name }}
          </div>
        </div>
      </div>
      <div class="text-center">
        <a href="#" @click.prevent="cancelMeeting(meeting.id)">Annuler mon rendez-vous</a>
      </div>
    </div>
  </div>
  <div v-show="displayWellbeing">
    <a class="btn-pink" href="#wellbeing">Remplir mon baromètre</a>
  </div>
  <CallDetail :phone="meeting.phone_number" :room="meeting.room_number" />
  <div>
    <a :href="calendarUrl" target="_blank">Ajouter ce RDV à mon agenda</a>
  </div>
</template>

<script>
import moment from "moment";
import CallDetail from "@/components/Meeting/CallDetail";
import meetingsService from "@/services/meetings.service";
import Toastify from "toastify-js";
export default {
  name: "ConfirmedMeeting",
  props: {
    meeting: Object,
    displayWellbeing: Boolean
  },
  components: {CallDetail},
  computed: {
    formatedDate() {
      return moment(this.meeting.starts_at).format('dddd Do MMMM à HH:mm').toLowerCase()
    },
    calendarUrl() {
      return 'https://www.google.com/calendar/render?action=TEMPLATE&text=Rendez-vous+avec+'+this.meeting.coach.first_name+'&dates='+moment(this.meeting.starts_at).format('YYYYMMDDTHHmmssZ')+'/'+moment(this.meeting.starts_at).add(30, 'minutes').format('YYYYMMDDTHHmmssZ')+'&details=A+l\'heure+du+rendez+:<br />J\'appelle+le+numéro+suivant+:<strong>+'+this.meeting.phone_number+'</strong><br />+Je compose mon numéro de conférence+:+<strong>'+this.meeting.room_number+'</strong>'+ (this.meeting.boost_pack ? '&add=' + this.meeting.boost_pack.manager.email : '') +'&location=&sprop=&sprop=name:'
    }
  },
  methods: {
    cancelMeeting(id) {
      meetingsService.cancelMeeting(id)
          .then(() => {
            if (this.meeting.boost_pack) {
              this.$router.push({name: 'BoostPackMeeting'})
            } else {
              this.$router.push({name: 'Meeting'})
            }
            Toastify({
              text: "Rendez-vous annulé",
              className: "success",
            }).showToast();
          });
    }
  }
}
</script>
