<template>
  <div class="call-detail">
    <div>
      <div>
        <h2><strong>A l'heure du rendez-vous :</strong></h2>
        <p>J'appelle le numéro suivant : {{ phone }}</p>
        <p>Je compose mon numéro de conférence : {{ room }}</p>
      </div>
    </div>
    <div>
      <img src="@/assets/images/note-icon.png" />
      <div>
        <h2><strong>Memo :</strong></h2>
        <p>Notez bien le numéro de la conférence</p>
        <p>En utilisant la fonction "ajouter à mon agenda",<br />
          vous vous assurez de conserver les éléments.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CallDetail",
  props: ['phone', 'room']
}
</script>