<template>
  <TitleMeeting title="Rendez-vous confirmé !" />
  <ConfirmedMeeting v-if="meeting" :meeting="meeting" :displayWellbeing="displayWellbeing" />
  <div id="wellbeing" v-if="!isBoostPack" class="wellbeing-form" v-show="displayWellbeing">
    <h2>Prenez quelques instant pour remplir<br />notre baromètre bien-être au travail</h2>
    <Wellbeing v-if="meeting" :display-wellbeing="displayWellbeing" :anonymous_identifier="meeting ? meeting.anonymous_user.identifier : null" :editable="true" />
  </div>
  <div class="back-portal">
    <router-link v-if="isBoostPack" :to="{name: 'BoostPackDashboard'}" class="btn-pink">Retour au portail</router-link>
  </div>
</template>

<script>
import TitleMeeting from "@/components/Meeting/TitleMeeting";
import ConfirmedMeeting from "@/components/Meeting/ConfirmedMeeting";
import meetingsService from "@/services/meetings.service";
import Wellbeing from "@/components/Wellbeing/Wellbeing";

export default {
  name: "MeetingConfirmationView",
  components: {Wellbeing, ConfirmedMeeting, TitleMeeting},
  data() {
    return {
      meeting: null,
      displayWellbeing: false,
      isBoostPack: false
    }
  },
  methods: {
    updateDisplayWellbeing(val) {
      this.displayWellbeing = val;
    }
  },
  mounted () {
    document.body.classList.add('bg-grey')

    if (this.$store.state.user.barometer) {
      meetingsService.batchCriteriasMeeting(this.$route.params.id, this.$store.state.user.barometer).then(() => {
      });
    }

    this.isBoostPack = !!this.$store.getters['user/getBoostPack']

    meetingsService.getMeeting(this.$route.params.id)
        .then(
          (response) => {
            this.meeting = response.data.data.meeting;
          },
          (error) => {
            if (error.response.status === 404) {
              this.$router.push({name: 'NotFound' })
            } else {
              return Promise.reject(error)
            }
          });
  },
  unmounted () {
    document.body.classList.remove('bg-grey')
  },
}
</script>
